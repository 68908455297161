.request-form-sucess {
  a {
    width: calc(100% - (var(--space-small) * 2));
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--white) !important;
    margin-top: 32px !important;
  }
}
