.alert-col {
  padding: 0 var(--space-small);
  margin-bottom: 26px;

  //#455FE4
  .alert-primary{
    color: #455FE4;
    background-color: #F0F2FE;
    border-color: #455FE4;
    font-family: 'Outfit';
    font-size: 14px;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    background-color: transparent;
    border: 0;
    position: absolute;
    top: -26px;
    right: -30px;
    padding: 0.75rem 1.25rem;
    color: inherit;
    cursor: pointer;

    span{
        height: 28px;
        width: 28px;
        background: #F0F2FE;
        border: 1px solid #455FE4;
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
    }
  }
}
