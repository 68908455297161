$menu-header-height : 52px;
$menu-footer-height : 52px;

$menu-header-btn-height : 52px;
$menu-footer-btn-height : 52px;

.menu-footer {
  position: fixed;
  bottom: 0;
  height: 52px;
  padding: 0 var(--space-small);
  border-top: 1px solid var(--indigo50);
  width: 100%;
  background-color: var(--white);
  z-index: 1000;

  .p-small {
    color: var(--indigo700);
    a {
      color: var(--indigo700);
    }
  }

  .menu-footer-actions {
    justify-content: flex-end;
    display: flex;
    padding-right: var(--space-small);
    .menu-button {
      height: $menu-header-btn-height;
      &:empty {
        border-radius: 0;
        padding: 0;
      }

      border-radius: 0;
      padding: 0;
    }
  }
}


.footer-tag{
  background: var(--yellow700)
}

