.search-container {
	display: block;
	padding: 0 var(--space-small);
	margin: var(--space-small) auto;
	background: var(--white);
	.form-group {
		margin: 0;
	}
}

.search-container.projects {
	display: none;
}
