.image-results-container {
    /**
        Menu header - 52px
        Menu seaerch input - 52px
        Menu hsearch input margin - 32px
        Menu footer - 52px
        Total = (52 * 3 ) + 32 == 188
    */
    height: calc(100vh - 188px);
}
