#overlay-spinner {
  position: absolute;
  left: 0;
  top: 52px;
  bottom: 52px;
  z-index: 10800;
  width: 100%;
  height: calc(100vh - 104px);
  display: none;
  overflow: visible;
  background: hsla(0deg, 0%, 100%, 0.95);
  background-repeat: no-repeat;
  background-position: 50%;
  .spinner {
    position: absolute;
    top: calc(50vh - 80px);
    left: calc(50vw - 4px);
    z-index: 9999;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    img {
      width: 100%;
    }
  }
  &.show {
    display: block;
  }
}
