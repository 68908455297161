.new-upload{
    justify-content: center;
    align-items: center;
    border: 1px solid var(--indigo50);
    width: 100%;
    height: 180px;
    border-radius: 4px;
    display: flex;
    align-content: center;
    span{
        color: var(--indigo700);
        cursor: pointer;
    }
}