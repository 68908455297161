.upload-image-placeholder {
    border: 1px solid var(--indigo50);
    width: 100%;
    height: 180px;
    border-radius: 4px;
    display: flex;
    align-content: center;
    overflow: hidden;
    .draggable-item{
        width: auto !important;
    }
}