.seperator {
  text-align: center;
  display: block;
  margin-top: 0;
}

.app-container{
  .title-h4{
    margin-top: 12px;
  }
}


p{
  span{
    text-decoration: underline;
  }
}
