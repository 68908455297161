.image-results-container {
  display: block;
  padding: 0 var(--space-small);
  margin: 0 auto 0 auto;
  background: var(--white);
  overflow-y: auto;
  align-self: start;
  width: auto;

  .image-placeholder {
    min-height: 146px;
  }
}
