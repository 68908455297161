.favorite-results-container {
  display: block;
  padding: 0 var(--space-small);
  margin: 16px auto 0 auto;
  background: var(--white);
  overflow-y: auto;
  align-self: start;
  width: auto;
  .image-placeholder {
    min-height: 146px;
  }

  .load-more-images {
    width: 100%;
    padding: 16px 0;
    button {
      width: 100%;
    }
  }
}
