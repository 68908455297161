.accordion {
  .accordion-item {
    border-radius: 0;
    border: none;
    .accordion-header {
      font-size: 16px;
      color: var(--indigo700);
      .accordion-button {
        font-size: 16px;
        color: var(--indigo700);
        padding-left: 0;
        padding-right: 0;
        background: #fff;
        &:focus {
            border-color: none;
            outline: 0;
            box-shadow: none;
        }
        &::after{ // +
            background-image : var(--bs-accordion-btn-icon-plus)
        } 
        &:not(.collapsed)::after{ // -
            background-image : var(--bs-accordion-btn-icon-minus)
        }
      }
    }
    .accordion-body{
        padding-left: 0;
        padding-right: 0;
        color: #5F5C80;
        font-size: 16px;
        text-align: justify;
    }
  }
}
