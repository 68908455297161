.dropdown-button-wrap {
  display: flex;
  align-items: center;

  .btn-group {
    border-radius: 0;
    #dropdown-button-drop-up {
      background: #ffffff;
      border: none;
      border-radius: 0;
      height: 54px;
      display: flex;
      align-items: center;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: var(--icon-size);
      background-color: var(--white);
      padding-top: 0;
      padding-bottom: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 20.75a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5ZM12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20ZM8.25 9.5a3.75 3.75 0 1 1 7.5 0v1.25a3.75 3.75 0 1 1-7.5 0V9.5ZM12 7a2.5 2.5 0 0 0-2.5 2.5v1.25a2.5 2.5 0 0 0 5 0V9.5A2.5 2.5 0 0 0 12 7ZM7.99 18.29a6.84 6.84 0 0 1 8.02 0 .63.63 0 0 0 .73-1.01 8.1 8.1 0 0 0-9.48 0 .62.62 0 1 0 .73 1.01Z'/%3E%3C/svg%3E");
      width: 48px;
      position: relative;
      vertical-align: text-top;
      &:empty::after {
        border: none;
        content: none;
        background: none;
      }
      &:before {
        border: 2px solid transparent;
        border-radius: var(--border-radius-medium);
        bottom: -1px;
        content: "";
        left: -1px;
        position: absolute;
        right: -1px;
        top: -1px;
      }
    }
  }

  .dropdown-menu {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 12%);
    border-radius: 4px;
    z-index: 1001;
    right: 16px;
    bottom: -8px;
    a {
      font-size: var(--font-size-medium);
      color: var(--indigo700);
      text-decoration: none;
      &:nth-child(5) {
        color: var(--red700);
      }
    }
  }
}
