.login-form {
  h2 {
    /* margin-bottom: 32px; */
  }
  img {
    width: 200px;
    margin: 0 auto;
    display: block;
  }
  form {
    margin-top: 32px;
    width: 100%;
  }
  .p-small {
    font-size: var(--font-size-small) !important;
    margin-bottom: var(--space-small);
    margin-left: auto;
    text-align: right;
    display: block;
    border: none;
    text-decoration: underline;
  }
  .seperator {
    text-align: center;
    display: block;
    margin-top: 1rem;
  }

  .button {
    margin: 0 !important;
    width: calc(100% - (var(--space-small) * 2));
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--white);
    margin-top: 32px !important;
    &.button-secondary {
      color: var(--indigo700) !important;
      margin-top: 0 !important;
    }
  }

  .eye-icon{
    position: absolute;
    width: 32px;
    height: 32px;
    color: #000;
    z-index: 1000;
    top: 10px;
    right: 0;
    cursor: pointer;
  }
}
