.seperator {
  text-align: center;
  display: block;
  margin-top: 0;
}

p{
  span{
    text-decoration: underline;
  }
}
