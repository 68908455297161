.upload-results-container {
  display: block;
  padding: 0 var(--space-small);
  margin: 16px auto 0 auto;
  background: var(--white);
  overflow-y: auto;
  align-self: start;
  width: auto;
  .image-placeholder {
    min-height: 146px;
    border: none !important;
    flex-direction: column;
    align-items: flex-start !important;
    border-radius: 0 !important;

    .name{
      font-size: 12px;
      padding: 8px 0;
      display: block;
    }
  }

  .load-more-images {
    width: 100%;
    padding: 16px 0;
    button {
      width: 100%;
    }
  }
}
