.login-steps {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  li {
    display: inline-block;
    a {
      width: 12px;
      height: 12px;
      display: inline-block;
      background: #ffffff;
      border-radius: 50%;
      border: 1px solid #050038;
      margin: 0 5px;
      cursor: pointer;
      &.active{
        background: #050038;
      }
    }
  }
}

p {
  &.p-description{
    margin: 16px 0 !important;
  }
  span {
    text-decoration: underline;
  }
}
