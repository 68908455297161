.auth-guest-menu {
  width: 100%;
    a {
      width: calc(100% - (var(--space-small) * 2));
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: var(--white);
      margin-top: 32px !important;
      &.button-secondary{
        color: var(--indigo700) !important;
        margin-top: 0 !important;
      }
      &:hover{
        color: var(--white);
      }
    }
  }