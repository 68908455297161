.empty-viwe-container{

    margin: 0 !important;
    &.uploads{
        height: calc(100vh - 104px) !important;
    }
    
    a.button{
     margin-bottom: 32px;   
    }
}
