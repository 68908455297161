$menu-header-height : 52px;
$menu-footer-height : 52px;

$menu-header-btn-height : 52px;
$menu-footer-btn-height : 52px;

#uploads-tab-button{
  display: none;
}

.menu-header {
  height: $menu-header-height;
  border-top: 1px solid var(--indigo50);
  border-bottom: 1px solid var(--indigo50);
  width: 100%;
  display: flex;

  .text-center {
    width: 25%;
    flex-grow: 1;
  }

  .menu-button {
    height: $menu-header-btn-height;
    display: block;
    &:empty {
      width: 100%;
      border-radius: 0;
      padding: 0;
    }
    width: 100%;
    border-radius: 0;
    padding: 0;
    background-position: 50%;
    &.active {
      background-color: var(--indigo200);
  }
  }
}
